<template>
	<main-menu/>
	<router-view/>
	<main-footer/>
</template>

<script>
import {RouterView} from 'vue-router'
import MainMenu from "./layout/MainMenu";
import MainFooter from "./layout/MainFooter";

export default {
	components: {
		MainMenu,
		MainFooter,
		RouterView,
	},
	computed: {
		param() {
			const params = new Proxy(new URLSearchParams(window.location.search), {
				get: (searchParams, prop) => searchParams.get(prop),
			});
			return params.vcm;
		},
	},
	watch: {
		$route: {
			immediate: true,
			handler(to, from) {
				document.title = 'Universidad de Aysén' + (to.meta.title ? ' - ' + to.meta.title : '');
			}
		},
	}
};
</script>