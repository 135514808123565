<template>
	<div>
		<header-box image="/assets/images/movilidad.jpg"
		            :opacity="true"
		            class-color="bg-cyan">
			<div
				class="fs-2 fw-lighter text-start text-xl-start text-lg-start text-md-center text-sm-center w-100 text-white">
				Para ti
			</div>
			<div class="h2 text-start text-xl-start text-lg-start text-md-center text-sm-center w-100 text-white">
				Programa de Movilidad Estudiantil
			</div>
		</header-box>
		
		<div class="container">
			<div class="row mb-5">
				<div class="col-xl-7 col-lg-7 col-md-12 col-sm-12 py-5">
					<p class="text-blue mb-5">
						"El Programa de movilidad te permitirá estudiar en otras universidades de Chile y el extranjero.
						Una oportunidad única que enriquecerá tu formación profesional y personal. ¡Vivirás nuevas
						experiencias que marcarán tu vida!<br><br>
						Al mismo tiempo, promovemos que estudiantes de otras universidades tengan la oportunidad de
						estudiar en la UAysén, generando un ambiente diverso que enriquece las relaciones y el
						aprendizaje dentro del campus".
					</p>
					<h5 class="text-blue">¿Cómo lo hacemos?</h5>
					<p class="text-blue mb-5">
						"Difundimos convocatorias, orientamos la postulación de cada estudiante a las universidades de
						destino, guiamos la postulación a becas y acompañamos durante todo el proceso de movilidad.
						Además, gestionamos los acuerdos de reconocimiento, documento que permite homologar las
						actividades académicas realizadas al finalizar la movilidad, es decir, la UAysén reconocerá las
						actividades cursadas y serán convalidadas.<br><br>
						Sabemos lo importante de acompañar a los y las estudiantes en movilidad para que cuenten con
						información y apoyo para tener la mejor experiencia".
					</p>
					<h5 class="text-blue">¿Cuáles son los tipos de movilidad?</h5>
					<p class="text-blue">
						En general, se dividen en movilidad Nacional e Internacional, las que pueden ser en modalidad
						presencial y virtual.
						<br><br>
						Así, se pueden realizar diversas actividades académicas como:
					</p>
					<ul class="text-blue w-100 d-block mb-5">
						<li>Asignaturas del plan de estudio de una carrera</li>
						<li>Asignaturas electivas</li>
						<li>Pasantías disciplinares</li>
						<li>Internados y prácticas</li>
						<li>Tesis de investigación</li>
						<li>Cualquier otra actividad académica reconocida por la institución de origen.</li>
					</ul>
					<h5 class="text-blue">¿Dónde puedes encontrarnos?</h5>
					<ul class="text-blue mb-5 list-unstyled">
						<li>Síguenos en Instagram <a href="https://www.instagram.com/movilidad.uaysen/" target="_blank">@movilidad.uaysen</a>
						</li>
						<li>Escríbenos a <a
							href="mailto:movilidad.estudiantil@uaysen.cl">movilidad.estudiantil@uaysen.cl</a></li>
						<li>o infórmate en <a href="https://movilidadnacional.cl ">https://movilidadnacional.cl</a></li>
					</ul>
				</div>
				<div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
					<!--div class="bg-gray py-5 mb-5">
						<div class="mx-4">
							<div class="row">
								<div class="col-12">
									<div class="rounded bg-blue-opacity-50 text-white text-center p-2 h5 mb-3">
										Documentos Relevantes
									</div>
								</div>
							</div>
							<a href=""
							   target="_blank"
							   class="btn btn-sky-blue d-block shadow text-uppercase w-75 mx-auto mb-3">
								Descargar Formulario
							</a>
							<a href=""
							   target="_blank"
							   class="btn btn-sky-blue d-block shadow text-uppercase w-75 mx-auto mb-3">
								Descargar Reglamento
							</a>
						</div>
					</div-->
					<div class="row py-5 mb-5">
						<div class="col-12">
							<profile cargo="Responsable de Movilidad Estudiantil"
							         correo="movilidad.estudiantil@uaysen.cl"
							         foto="/assets/images/unidades/movilidad/karla.movilidad.jpg"
							         nombre="Karla Muñoz Ramírez"></profile>
							
							<br>
							
							<div class="rounded bg-blue-opacity-50 text-white text-center p-2 h5 mb-3">
								Horarios de funcionamiento
							</div>
							
							<ul class="list-unstyled">
								<li class="text-blue fw-bold text-center">Casa Angol, Magallanes 511</li>
								<li class="text-blue mb-3 text-center">Lunes a viernes: 09:00 a 13:00 / 14:30 a 18:00
								</li>
							</ul>
						</div>
					</div>
					<!--div class="bg-gray py-5 mb-5">
						<div class="mx-4">
							<div class="row">
								<div class="col-12">
									<div class="rounded bg-blue-opacity-50 text-white text-center p-2 h5 mb-3">
										Enlaces de Interes
									</div>
								</div>
							</div>
							<a href="https://movilidadnacional.cl/"
							   target="_blank"
							   class="btn btn-sky-blue d-block shadow text-uppercase w-75 mx-auto mb-3">
								Movilidad Súbete
							</a>
						</div>
					</div-->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import HeaderBox from "../components/HeaderBox";
import LinkTo from "../components/LinkTo";
import Profile from "../components/Profile";

export default {
	name: "servicio-programa-movilidad",
	components: {LinkTo, HeaderBox, Profile},
	data: () => ({}),
	methods: {},
	computed: {}
}
</script>