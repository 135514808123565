<template>
	<div :class="containerClass">
		<div class="modal fade" :id="id" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog" :class="modalClasses">
				<div class="modal-content">
					<div v-if="$slots.title" class="modal-header">
						<h5 class="modal-title">
							<slot name="title" :modal="this"></slot>
						</h5>
						<button type="button" class="btn-close" @click="close"></button>
					</div>
					<div v-if="$slots.body" class="modal-body p-0">
						<slot name="body" :modal="this"></slot>
					</div>
					<div v-if="$slots.footer" class="modal-footer">
						<slot name="footer" :modal="this"></slot>
					</div>
				</div>
			</div>
		</div>
		<slot name="trigger" :modal="this"></slot>
	</div>
</template>

<script>
import _ from "lodash";

export default {
	name: 'modal',
	props: {
		size: {
			type: String,
			default: '',
			validator: function (value) {
				return ['fullscreen', 'xl', 'lg', '', 'sm'].indexOf(value) !== -1;
			}
		},
		scrollcrable: {
			type: Boolean,
			default: true
		},
		closable: {
			type: Boolean,
			default: true
		},
		containerClass: {
			type: String,
			default: ''
		},
	},
	data: () => ({
		id: _.uniqueId('modal-'),
		instance: null,
	}),
	mounted() {
		this.instance = new bootstrap.Modal(document.getElementById(this.id), {
			backdrop: !this.closable ? 'static' : true,
			keyboard: this.closable,
		});
	},
	methods: {
		open() {
			this.instance.show();
		},
		close() {
			this.instance.hide();
		},
	},
	beforeUnmount() {
		this.instance.dispose();
	},
	computed: {
		modalClasses() {
			let classes = [];
			if (this.size) {
				classes.push('modal-' + this.size);
			}
			if (this.scrollcrable) {
				classes.push('modal-dialog-scrollable');
			}
			return classes;
		}
	}
};
/*
<modal ref="modal" size="lg" :scrollcrable="true" closable="true">
			<template v-slot:trigger="{modal}">
				<button @click="modal.open">
					show
				</button>
			</template>
			<template v-slot:title>
				title
			</template>
			<template v-slot:body>
				<img src="/assets/images/unidades/biblioteca/pablo-olivares.jpeg" class="w-100">
			</template>
			<template v-slot:footer="{modal}">
				<button @click="modal.close">
					show
				</button>
			</template>
		</modal>
* */
</script>