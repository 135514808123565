import Error404 from "./pages/Error404";
import Inicio from "./pages/Inicio";
import CalendarioAcademico from "./pages/CalendarioAcademico";
import SecretariaAcademica from "./pages/SecretariaAcademica";
import Biblioteca from "./pages/Biblioteca";
import ServicioUnidadCuidados from "./pages/ServicioUnidadCuidados";
import ServicioDesarrolloEstudiantil from "./pages/ServicioDesarrolloEstudiantil";
import ServicioProgramaMovilidad from "./pages/ServicioProgramaMovilidad";
import ServicioPace from "./pages/ServicioPace";
import ServicioUnidadEquidadGenero from "./pages/ServicioUnidadEquidadGenero";
import ServicioAcompanamientoAcademico from "./pages/ServicioAcompanamientoAcademico";
import ServicioActividadesExtensionUniversitaria from "./pages/ServicioActividadesExtensionUniversitaria";
import TEMPServicioDesarrolloEstudiantil from "./pages/TEMPServicioDesarrolloEstudiantil";

const params = new Proxy(new URLSearchParams(window.location.search), {
	get: (searchParams, prop) => searchParams.get(prop),
});
// Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
let value = params.vcm;
let routes;
// if (value && value !== '') {
	routes = [
		{name: 'inicio', path: '/', component: Inicio, meta: {title: 'Inicio'}},
		{name: 'calendario-academico', path: '/calendario-academico', component: CalendarioAcademico, meta: {title: 'Calendario Académico'}},
		{name: 'secretaria-academica', path: '/secretaria-academico', component: SecretariaAcademica, meta: {title: 'Secretaría Académica'}},
		{name: 'biblioteca', path: '/biblioteca', component: Biblioteca, meta: {title: 'Biblioteca'}},
		{name: 'servicio-unidad-cuidados', path: '/servicios-uaysen/unidad-cuidados', component: ServicioUnidadCuidados, meta: {title: 'Servicios UAysén'}},
		{name: 'servicio-desarrollo-estudiantil', path: '/servicios-uaysen/desarrollo-estudiantil', component: ServicioDesarrolloEstudiantil, meta: {title: 'Servicios UAysén'}},
		{name: 'servicio-programa-movilidad', path: '/servicios-uaysen/programa-movilidad', component: ServicioProgramaMovilidad, meta: {title: 'Servicios UAysén'}},
		{name: 'servicio-pace', path: '/servicios-uaysen/pace', component: ServicioPace, meta: {title: 'Servicios UAysén'}},
		{name: 'servicio-unidad-equidad-genero', path: '/servicios-uaysen/unidad-equidad-genero', component: ServicioUnidadEquidadGenero, meta: {title: 'Servicios UAysén'}},
		{name: 'servicio-acompanamiento-academico', path: '/servicios-uaysen/acompanamiento-academico', component: ServicioAcompanamientoAcademico, meta: {title: 'Servicios UAysén'}},
		{name: 'servicio-actividades-extension-universitaria', path: '/servicios-uaysen/actividades-extension-universitaria', component: ServicioActividadesExtensionUniversitaria, meta: {title: 'Servicios UAysén'}},
		{name: 'error-not-found', path: '/:catchAll(.*)', component: Error404, meta: {title: 'Error 404'}}
	];
// } else {
// 	routes = [
// 		{name: 'error-not-found', path: '/:catchAll(.*)', component: TEMPServicioDesarrolloEstudiantil, meta: {title: 'Actividades Extraprogramáticas 1° Semestre'}}
// 	];
// }

export default routes;