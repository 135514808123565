<template>
	<div>
		<header-box image="/assets/images/cuidados.jpg"
		            :opacity="true"
		            class-color="bg-cyan">
			<div
				class="fs-2 fw-lighter text-start text-xl-start text-lg-start text-md-center text-sm-center w-100 text-white">
				Para ti
			</div>
			<div class="h2 text-start text-xl-start text-lg-start text-md-center text-sm-center w-100 text-white">
				Unidad de Cuidados
			</div>
		</header-box>

		<div class="container">
			<div class="row mb-5">
				<div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 py-5">
					<div class="row">
						<div class="col-xl-7 col-lg-9 col-md-12 col-sm-12">
							<div class="title bg-cyan mb-3">Unidad de Cuidados</div>
						</div>
					</div>
					<p class="text-blue mb-5">
						La Unidad de Cuidado consiste en un servicio de guardería y cuidado de niños/as a partir de los
						4 años de edad y hasta los 10 años, dirigido a hijos/as, hermanos/as u otro niñ@ al cuidado de
						estudiantes de la Universidad de Aysén, que no cuenten con redes de apoyo para su cuidado
						durante el horario de actividades académicas. Este servicio también está abierto al cuidado de
						hij@s de funcionarios y académicos, de acuerdo a la disponibilidad de cupos.
					</p>

					<div class="row">
						<div class="col-xl-7 col-lg-9 col-md-12 col-sm-12">
							<div class="title bg-cyan mb-3">Requisitos</div>
						</div>
					</div>

					<div v-for="requisito in requisitos"
					     class="mb-1 d-flex justify-content-start text-justify">
						<i class="text-cyan mt-1 me-2 fa fa fa-dot-circle"></i>
						<span class="text-blue" v-html="requisito"/>
					</div>
				</div>
				<div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
					<div class="bg-gray py-5 mb-5">
						<div class="mx-4">
							<div class="row">
								<div class="col-12">
									<div class="rounded bg-blue-opacity-50 text-white text-center p-2 h5 mb-3">
										Requisitos para utilizar<br/>Unidad de Cuidados
									</div>
								</div>
							</div>
							<a href="https://docs.google.com/forms/d/1dI-c1RZTbI7JmqLtf_0MhEmkentJW9Dti8PCfNSFQ-4/viewform?ts=6220ad6c&edit_requested=true"
							   target="_blank"
							   class="btn btn-sky-blue d-block shadow text-uppercase w-75 mx-auto mb-3">
								Ficha de Postulación
							</a>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<div class="rounded bg-blue-opacity-50 text-white text-center p-2 h5 mb-3">
								Horarios de funcionamiento
							</div>
						</div>
					</div>
					<ul class="list-unstyled mb-5">
						<li class="text-blue fw-bold text-center">Casa Angol, Magallanes 511</li>
						<li class="text-blue fw-bold text-center">Lunes a Viernes:</li>
						<li class="text-blue text-center">09:00 a 13:00 / 14:30 a 18:00</li>
					</ul>

				</div>
			</div>
		</div>

		<div class="bg-dark-gray py-5">
			<div class="container">
				<div class="row">
					<div v-for="funcionario in funcionarios" class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-5">
						<profile :cargo="funcionario.cargo"
						         :correo="funcionario.correo"
						         :foto="funcionario.foto"
						         :nombre="funcionario.nombre"></profile>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import HeaderBox from "../components/HeaderBox";
import LinkTo from "../components/LinkTo";
import mock from "../common/mock";
import Profile from "../components/Profile";

export default {
	name: "servicio-unidad-cuidados",
	components: {LinkTo, HeaderBox, Profile},
	data: () => ({
		funcionarios: [
			// {
			// 	cargo: 'Responsable de la Unidad de Cuidados',
			// 	correo: 'veronica.montero@uaysen.cl',
			// 	foto: '/assets/images/unidades/cuidados/veronica_montero_catalan.jpg',
			// 	nombre: 'Verónica Montero',
			// },
			{
				cargo: 'Profesional de la Unidad de Cuidados',
				correo: 'pilar.zuniga@uaysen.cl',
				foto: '/assets/images/unidades/cuidados/pilar-zuniga.jpg',
				nombre: 'Pilar Zúñiga',
			},
			{
				cargo: 'Profesional de la Unidad de Cuidados',
				correo: 'meugenia.jelbes@uaysen.cl',
				foto: '/assets/images/unidades/cuidados/mariaefugeniajelbes.jpg',
				nombre: 'María Eugenia Jelbes',
			}
		],
	}),
	methods: {},
	computed: {
		requisitos() {
			return mock.requisitosUnidadCuidados;
		}
	}
}
</script>
