import LinkTo from "../components/LinkTo";
import Header from "../components/HeaderBox";

const Components = [
	LinkTo,
	Header
];
const Install = {
	install: (app) => {
		for (const c of Components) {
			app.component(c.name, c);
		}
	}
}
export {Install as default, Components};