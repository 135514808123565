<template>
	<div>
		<header-box image="/assets/images/genero.jpg"
		            :opacity="true"
		            opacity-color="bg-cyan"
		            class-color="bg-purple">
			<div
				class="fs-2 fw-lighter text-start text-xl-start text-lg-start text-md-center text-sm-center w-100 text-white">
				Para ti
			</div>
			<div class="h2 text-start text-xl-start text-lg-start text-md-center text-sm-center w-100 text-white">
				Unidad de Equidad de Género
			</div>
		</header-box>

		<div class="container">
			<div class="row">
				<div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 py-5">
					<p class="text-blue mb-5">
						La Unidad de Equidad de Género (UEG) tiene como objetivo promocionar la igualdad de género en
						la Universidad de Aysén. Para ello realiza distintas acciones, entre ellas garantizar un espacio
						plural y libre de violencia de género y de discriminación.
						<br>
						Si has sido víctima de acoso sexual, violencia de género o discriminación arbitraria, o conoces
						a alguien que la ha vivido dentro de la Universidad, o necesitas mayor información, puedes
						contactar a la UEG.
					</p>
					<div class="h5 text-blue mb-5">
						<b>¿Cómo ponerte en contacto con la UEG?</b>
					</div>

					<p class="text-blue">
						Puedes escribir tus dudas a genero@uaysén.cl, o enviar el formulario de denuncias. También
						puedes comunicarte vía telefónica al <a href="tel:67-2332233">67-2332233</a>, o bien entrar al
						sitio web: <a href="https://uaysen.cl/equidad-genero" target="_blank">//www.uaysen.cl/equidad-genero</a>
					</p>
					<h5 class="text-blue">"Te escucharemos, te orientaremos y atenderemos de manera confidencial".
						Equipo UEG</h5>
				</div>
				<div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
					<div class="bg-gray py-5">
						<div class="mx-4 mb-3">
							<div class="row">
								<div class="col-12">
									<div class="rounded bg-blue-opacity-50 text-white text-center p-2 h5 mb-3">
										Descarga aquí protocolos vigentes
									</div>
								</div>
							</div>

							<a href="https://uaysen.cl/uploads/archivos/genero/protocolo-vsda-1.pdf"
							   target="_blank"
							   class="btn btn-sky-blue d-block shadow w-100 mx-auto mb-3">
								Protocolo ante denuncias de acoso sexual, violencia de género y/o discriminación arbitraria
							</a>
							<a href="https://uaysen.cl/uploads/archivos/decreto/genero/1805-universidad-de-aysen-aprueba-protocolo-sobre-aceptacion-y-reconocimiento-de-nombre-social-de-toda-persona-trans.pdf"
							   target="_blank"
							   class="btn btn-sky-blue d-block shadow w-100 mx-auto mb-3">
								Protocolo sobre Aceptación y Reconocimiento de Nombre Social de toda Persona Trans
							</a>
						</div>
						<div class="mx-4 mb-3">
							<div class="row">
								<div class="col-12">
									<div class="rounded bg-blue-opacity-50 text-white text-center p-2 h5 mb-3">
										Preguntas y Respuestas
									</div>
								</div>
							</div>

							<a href="/assets/pdf/preguntas-y-respuestas-sobre-como-realizar-una-denuncia.pdf"
							   target="_blank"
							   class="btn btn-sky-blue d-block shadow w-100 mx-auto mb-3">
								Ver aquí
							</a>
						</div>
						<div class="mx-4 mb-3">
							<div class="row">
								<div class="col-12">
									<div class="rounded bg-blue-opacity-50 text-white text-center p-2 h5 mb-3">
										Formulario de Denuncia
									</div>
								</div>
							</div>

							<a href="https://uaysen.cl/uploads/archivos/genero/formulario-denuncias-violencia-sexual-discriminacion-arbitraria.docx"
							   target="_blank"
							   class="btn btn-sky-blue d-block shadow w-100 mx-auto mb-3">
								Descarga aquí
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="bg-dark-gray py-5">
			<div class="container">
				<div class="row justify-content-between">
					<div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-5">
						<div class="h5 text-blue mb-5">
							<b>Equipo Unidad de Género</b>
						</div>

						<profile v-for="funcionario in funcionarios"
						         :cargo="funcionario.cargo"
						         :correo="funcionario.correo"
						         :foto="funcionario.foto"
						         :nombre="funcionario.nombre"></profile>
					</div>
					<div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
						<div class="row">
							<div class="col-12">
								<div class="rounded bg-blue-opacity-50 text-white text-center p-2 h5 mb-3">
									Horarios de funcionamiento
								</div>
							</div>
						</div>
						<ul class="list-unstyled">
							<li class="text-blue fw-bold text-center">Campus Rio Simpson</li>
							<li class="text-blue text-center">Lunes a Viernes: 08:30 a 18:00</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<div class="bg-dark-pink py-5">
			<div class="container">
				<div class="row">
					<div class="col-12 h1 font-weight-bold pb-3 text-white-50">
						Comisión de Equidad de Género
					</div>
				</div>
				<div class="row">
					<div class="col-12 h5 pb-4 text-white">
						Son funciones de la Comisión de Equidad de Género:
					</div>
				</div>
				<div class="row">
					<div class="col-xl col-lg d-flex align-items-start text-white">
						<div class="bg-purple pb-2 ps-3 pe-2 me-2 h1 text-right">1</div>
						<div>Aprobar un Plan Operativo Anual de acciones que la
							Unidad de Equidad de Género propondrá y ejecutará en materia de equidad de género y
							especialmente contra la violencia sexual y la discriminación arbitraria, según lo
							establecido en el DUE 1116/2018.
						</div>
					</div>
					<div class="col-xl col-lg d-flex align-items-start text-white">
						<div class="bg-purple pb-2 ps-3 pe-2 me-2 h1 text-right">2</div>
						<div class="">Velar por la correcta ejecución del Plan Operativo
							Anual y de su financiamiento.
						</div>
					</div>
					<div class="col-xl col-lg d-flex align-items-start text-white">
						<div class="bg-purple pb-2 ps-3 pe-2 me-2 h1 text-right">3</div>
						<div>Proponer acciones de colaboración interna y
							externa, investigación, estudios y otras actividades que contribuyan a visualizar brechas
							y/o a generar medidas que contribuyan a la disminución de las mismas.
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-12 h5 pt-5 pb-4 text-white">
						La Comisión de Equidad de Género está compuesta por:
					</div>
				</div>

				<div class="row">
					<div class="col-12">
						<swiper :slides-per-view="1"
						        :space-between="50"
						        :modules="modules"
						        :navigation="true"
						        :pagination="true"
						        :scrollbar="true"
						        :breakpoints="{
									640  : {slidesPerView: 2, spaceBetween: 0},
									768  : {slidesPerView: 4, spaceBetween: 0},
									1024  : {slidesPerView: 5, spaceBetween: 0}
								}">
							<swiper-slide v-for="integrante in comision">
								<div class="p-4">
									<img class="mx-auto img-thumbnail w-75 d-block rounded-circle shadow"
									     draggable="false"
									     :src="integrante.imagen"
									     :alt="integrante.nombre">
									<div class="w-100 d-block text-center mt-3">
										<strong class="w-100 d-block text-white"
										        v-html="integrante.nombre"/>
										<small class="text-white-50"
										       v-html="integrante.cargo "/>
									</div>
								</div>
							</swiper-slide>
						</swiper>

					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import HeaderBox from "../components/HeaderBox";
import LinkTo from "../components/LinkTo";
import mock from "../common/mock";
import Profile from "../components/Profile";
import {Swiper, SwiperSlide} from 'swiper/vue';
import {Navigation, Pagination} from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default {
	name: 'servicio-unidad-equidad-genero',
	components: {LinkTo, HeaderBox, Profile, Swiper, SwiperSlide},
	data: () => ({
		modules: [Navigation, Pagination],
		funcionarios: [
			// {
			// 	cargo: 'Psicóloga Unidad de Equidad de Género',
			// 	correo: 'katherine.araya@uaysen.cl',
			// 	foto: '/assets/images/unidades/equidad-genero/katherine-araya.jpg',
			// 	nombre: 'Katherine Araya',
			// },
			{
				cargo: 'Profesional Unidad de Equidad de Género',
				correo: 'hayley.duran@uaysen.cl',
				foto: '/assets/images/unidades/equidad-genero/hayley-duran.jpg',
				nombre: 'Hayley Durán',
			},
		],
		comision: [
			{imagen: '/assets/images/unidades/equidad-genero/3_Romina_Aranda_comisión de genero.jpg', nombre: 'Romina Aranda', cargo: 'Jefa de Carrera Psicología'},
			{imagen: '/assets/images/unidades/equidad-genero/4_Joahana_Madrigal_comisión de genero.jpg', nombre: 'Johanna Madrigal', cargo: 'Académica Depto. Cs. Sociales'},
			{imagen: '/assets/images/unidades/equidad-genero/miguel-perez.jpg', nombre: 'Miguel Perez', cargo: 'Representante Dir. Vinculación con el Medio'},
			//{imagen: '/assets/images/unidades/equidad-genero/13_Antonia_Santos_comisión de genero.jpg', nombre: 'Antonia Santos', cargo: 'Coordinadora UEG'},
			{imagen: '/assets/images/unidades/equidad-genero/14_Hayley_Duran_comisión de genero.jpg', nombre: 'Hayley Durán', cargo: 'Profesional de apoyo UEG'},
			{imagen: '/assets/images/unidades/equidad-genero/12_Jeannette_Monsalve_comisión de genero.jpg', nombre: 'Jeannette Monsalve', cargo: 'Representante Dir. Académica'},
		],
	}),
	methods: {},
	computed: {
		requisitos() {
			return mock.requisitosUnidadCuidados;
		}
	}
}
</script>
