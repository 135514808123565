<template>
	<div>
		<header-box image="/assets/images/calendario.jpg"
		            :opacity="true"
		            class-color="bg-blue">
			<div class="h1 text-start text-xl-start text-lg-start text-md-center text-sm-center w-100 text-white">
				Biblioteca
			</div>
			<div class="h2 text-start text-xl-start text-lg-start text-md-center text-sm-center w-100 text-sky-blue">
				UAysén
			</div>
		</header-box>
		
		<div class="container">
			<div class="row mb-5">
				<div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 py-5">
					<div class="row">
						<div class="col-xl-7 col-lg-9 col-md-12 col-sm-12">
							<div class="title bg-cyan mb-3">Biblioteca</div>
						</div>
					</div>
					<p class="text-blue mb-5">
						La Biblioteca será un espacio fundamental durante tus años de Universidad. Contarnos con dos
						Bibliotecas, una en cada Campus, a disposición de tu proceso formativo. Puedes acceder al portal
						de recursos de biblioteca en <a class="text-decoration-underline" target="_blank"
						                                href="https://biblioteca.uaysen.cl">https://biblioteca.uaysen.cl</a>
						donde encontrarás recursos disponibles
						en biblioteca, enlaces a bases de datos, sitios web de interés, además de informar de manera
						periódica los nuevos recursos que se integran a la colección y noticias propias de la
						biblioteca.
					</p>
					
					<div class="row">
						<div class="col-xl-7 col-lg-9 col-md-12 col-sm-12">
							<div class="title bg-cyan mb-3">Servicios</div>
						</div>
					</div>
					<p class="text-blue mb-5">
						Libros físicos, libros digitales y acceso a bases de datos. Además, realizamos prestamos de
						notebooks, tablets y calculadoras. También facilitamos áreas de estudio.
					</p>
					<p class="text-blue mb-5">
						Para acceder a estos recursos puedes escribir a
						<a class="text-decoration-underline" href="mailto:biblioteca@uaysen.cl">biblioteca@uaysen.cl</a>
						Recuerda que, para la atención presencial, ¡deberás siempre presentar tu cédula de identidad!
					</p>
					
					<div class="row">
						<div class="col-xl-9 col-lg-9 col-md-12 col-sm-12">
							<div class="title bg-cyan mb-3">Tiempo de préstamo de material</div>
						</div>
					</div>
					
					<ul class="list-unstyled mb-5">
						<li class="text-blue"><b>Libros alta demanda:</b> 3 días hábiles</li>
						<li class="text-blue"><b>Libros colección general:</b> 7 días hábiles</li>
						<li class="text-blue"><b>Libros electrónicos:</b> de 7 a 14 días</li>
						<li class="text-blue"><b>Computador:</b> 3 horas</li>
						<li class="text-blue"><b>Tablet:</b> 6 horas</li>
					</ul>
				</div>
				<div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
					<div class="bg-gray py-5 mb-5">
						<div class="mx-5">
							<div class="h5 text-center text-blue fw-bold mb-4">Accesos Relevantes</div>
							<ul class="list-unstyled">
								<li v-for="relevante in relevantes">
									<a :href="relevante.link"
									   v-html="relevante.text"
									   class="btn btn-sky-blue d-block mb-3 text-center text-white shadow"
									   target="_blank"/>
								</li>
							</ul>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<div class="rounded bg-blue-opacity-50 text-white text-center p-2 h5 mb-3">Horarios de
								funcionamiento
							</div>
						</div>
					</div>
					<ul class="list-unstyled">
						<li class="text-blue fw-bold text-center">Campus Lillo</li>
						<li class="text-blue text-center">Lunes a jueves 8:00 a 20:00</li>
						<li class="text-blue mb-3 text-center">Viernes 8:00 a 19:00 hrs.</li>
						
						<li class="text-blue fw-bold text-center">Campus Rio Simpson</li>
						<li class="text-blue text-center">Lunes a jueves 8:00 a 17:00</li>
						<li class="text-blue mb-3 text-center">Viernes 8:00 a 16:00</li>
						<li class="text-blue fw-bold text-center">Colación</li>
						<li class="text-blue text-center">13:00 a 14:00 hrs</li>
					</ul>
				</div>
			</div>
		</div>
		
		<div class="bg-dark-gray py-5">
			<div class="container">
				<div class="row">
					<div v-for="funcionario in funcionarios" class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
						<profile :cargo="funcionario.cargo"
						         :correo="funcionario.correo"
						         :foto="funcionario.foto"
						         :nombre="funcionario.nombre"></profile>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import HeaderBox from "../components/HeaderBox";
import Profile from "../components/Profile";
import LinkTo from "../components/LinkTo";
import mock from "../common/mock";
import Modal from "../components/Modal";

export default {
	name: "secretaria-academica",
	components: {LinkTo, HeaderBox, Profile, Modal},
	data: () => ({
		funcionarios: [
			{
				cargo: 'Responsable Biblioteca',
				correo: 'pablo.olivares@uaysen.cl',
				foto: '/assets//images/unidades/biblioteca/pablo-olivares.jpeg',
				nombre: 'Pablo Olivares',
			},
			{
				cargo: '',
				correo: 'clauda.aranda@uaysen.cl',
				foto: 'assets/images/unidades/biblioteca/claudia-aranda.jpeg',
				nombre: 'Claudia Aranda',
			}
		],
		relevantes: mock.relevantes.filter(i => i.text === 'Biblioteca')
	})
}
</script>