<template>
	<div class="accordion-item border-0">
		<div class="accordion-header">
			<div class="accordion-button collapsed rm-after fw-bold cursor-pointer"
			     :class="activeClass"
			     data-bs-toggle="collapse"
			     :data-bs-target="'#'+id"
			     aria-expanded="false">
				<span>{{ title }}</span>
				<i class="fas fa-2x" :class="{
					'fa-caret-down': !isOpen,
					'fa-caret-up': isOpen
				}"></i>
			</div>
		</div>
		<div :id="id" class="accordion-collapse collapse"
		     :data-bs-parent="parent">
			<div class="accordion-body">
				<slot/>
			</div>
		</div>
	</div>
</template>

<script>
import _ from "lodash";

export default {
	name: 'accordion',
	props: {
		id: {
			type: String,
			default: '',
			required: true,
		},
		title: {
			type: String,
			default: '',
			required: true,
		},
		parent: {
			type: String,
			default: '',
			required: false,
		},
		btnClass: {
			type: String,
			default: '',
			required: false,
		},
		btnClassActive: {
			type: String,
			default: '',
			required: false,
		},
	},
	data: () => ({
		instance: null,
		isOpen: false,
	}),
	mounted() {
		const el = document.getElementById(this.id);
		el.addEventListener('show.bs.collapse', () => {
			this.isOpen = true;
		});
		el.addEventListener('hide.bs.collapse', () => {
			this.isOpen = false;
		});
	},
	computed: {
		activeClass() {
			return this.isOpen ? this.btnClassActive : this.btnClass;
		},
	}
}
</script>
