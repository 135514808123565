<template>
  <div>
    <header-box image="/assets/images/extencion.jpg"
                :opacity="true"
                class-color="bg-green"
                opacity-color="bg-cyan">
      <div
          class="fs-2 fw-lighter text-start text-xl-start text-lg-start text-md-center text-sm-center w-100 text-white">
        Para ti
      </div>
      <div class="h2 text-start text-xl-start text-lg-start text-md-center text-sm-center w-100 text-white">
        Actividades Extensión Universitaria
      </div>
    </header-box>

    <div class="container pb-5">
      <div class="row mb-3">
        <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 py-5">
          <div class="row">
            <div class="col-xl-7 col-lg-9 col-md-12 col-sm-12">
              <div class="title bg-cyan mb-3">Descripción</div>
            </div>
          </div>
          <p class="text-blue mb-5">
            La dirección de vinculación con el Medio de la Universidad de Aysén cuenta con tres
            ámbitos de acción bajo los que guía su acción: territorio, comunicaciones y extensión
            cultural, cumpliendo de esta manera con dos de sus objetivos estratégicos institucionales:
            promover el compromiso institucional con el entorno social, cultural, productivo y político
            de la región, a través de la generación de instancias de participación regional e incidir en
            temas de interés regional a través de las actividades de investigación, docencia y extensión
            cultural.
          </p>
          <p class="text-blue mb-5">
            <b> En este contexto el área de Extensión Cultural de la Universidad
              de Aysén tiene como objetivo:</b>
          <ul class="text-blue">
            <li>Crear y/o fortalecer los vínculos con actores del ámbito patrimonial, cultural y social</li>
            <li>Implementar acciones de Extensión Cultural</li>
            <li>Generar programación de actividades culturales que promuevan el desarrollo integral de la comunidad
              universitaria y general
            </li>
            <li>Promover el desarrollo cultural y la calidad de vida de las personas y sus comunidades. También basa
              su accionar en la Ley de Universidades Estatales
            </li>
          </ul>
          </p>


        </div>

      </div>
    </div>

    <div class="bg-dark-gray py-5">
      <div class="container">
        <div class="row">
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <profile cargo="Responsable Unidad de Extensión"
                     correo="mario.saldivia@uaysen.cl"
                     foto="/assets/images/unidades/extension/mario-saldivia.jpg"
                     nombre="Mario Saldivia Alvarez"></profile>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <profile cargo="Profesional Unidad de Extensión"
                     correo="elena.villarroel@uaysen.cl"
                     foto="/assets/images/unidades/extension/elena-villarroel.jpg"
                     nombre="Elena Villarroel Cárcamo"></profile>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
<!--            <profile cargo="Profesional Unidad de Extensión"-->
<!--                     correo="angela.ulloa@uaysen.cl"-->
<!--                     foto="/assets/images/unidades/extension/angela-ulloa.jpg"-->
<!--                     nombre="Ángela Ulloa Cuevas"></profile>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBox from "../components/HeaderBox";
import LinkTo from "../components/LinkTo";
import mock from "../common/mock";
import Profile from "../components/Profile";
import Accordion from "../components/Accordion";

export default {
  name: "servicio-actividades-extension-universitaria",
  components: {LinkTo, HeaderBox, Profile, Accordion},
  data: () => ({
    relevantes: mock.relevantes
  }),
  methods: {},
  computed: {
    programas() {
      return mock.programas;
    },
  }
}
</script>
