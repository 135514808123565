import _ from 'lodash';
import {createApp} from "vue";
import {createRouter, createWebHistory} from "vue-router";
import App from "./App.vue";
import routes from "./routes";
import Components from './common/components';

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		return {top: 0, behavior: "instant"}
	}
});
createApp(App)
.use(_)
.use(router)
.use(Components)
.mount('#app');