<template>
	<div>
		<header-box image="/assets/images/ude.jpg"
		            :opacity="true"
		            class-color="bg-orange"
		            opacity-color="bg-cyan">
			<div
				class="fs-2 fw-lighter text-start text-xl-start text-lg-start text-md-center text-sm-center w-100 text-white">
				Para ti
			</div>
			<div class="h2 text-start text-xl-start text-lg-start text-md-center text-sm-center w-100 text-white">
				Desarrollo Estudiantil UDE
			</div>
		</header-box>

		<div class="container pb-5">
			<div class="row mb-3">
				<div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 py-5">
					<div class="row">
						<div class="col-xl-7 col-lg-9 col-md-12 col-sm-12">
							<div class="title bg-orange mb-3">Descripción</div>
						</div>
					</div>
					<p class="text-blue mb-5">
						La Unidad de Desarrollo Estudiantil tiene como objetivo asegurar las condiciones necesarias para
						que los alumnos puedan desarrollar en forma adecuada su formación académica y profesional,
						abarcando especialmente aspectos relacionados con los perfiles socioeconómicos, desarrollo
						personal y capital social de los estudiantes de la institución.
					</p>
					<div class="row">
						<div class="col-xl-8 col-lg-9 col-md-12 col-sm-12">
							<profile cargo="Coordinadora"
							         correo="jeannette.monsalve@uaysen.cl"
							         foto="/assets/images/unidades/desarrollo-estudiantil/jeannette-monsalve.jpg"
							         nombre="Jeannette Monsalve Suter"></profile>
						</div>
					</div>
					<div class="row">
						<div class="col-xl-7 col-lg-9 col-md-12 col-sm-12 my-4">
							<div class="title bg-cyan mb-5">Áreas de trabajo UDE</div>
						</div>
					</div>

					<div id="accordion-servicios" class="">
						<accordion id="becas-beneficios" title="Becas y Beneficios"
						           class="mb-4"
						           btn-class="bg-orange text-white"
						           btn-class-active="bg-orange-opacity-50 text-white"
						           parent="#accordion-servicios">
							<p class="text-blue mb-4">
								Está a cargo de un (a) profesional Asistente Social que realiza una labor de consejería
								y orientación para la postulación y renovación de los beneficios estudiantiles internos
								y externos de la universidad.
							</p>
							<p class="text-blue mb-4">
								Los y las estudiantes regulares de pregrado podrán acceder a Gratuidad, Becas de Arancel
								y Créditos Universitarios financiados por el Ministerio de Educación. También podrán
								acceder a las Becas de Mantención gestionadas por Junaeb, tales como: BAES, Beca Aysén,
								Beca de Integración Territorial, Beca índigena, Beca Presidente de la República, TNE,
								entre otras.
							</p>
							<p class="text-blue mb-4">
								La Universidad de Aysén cuenta con becas internas a las que los y las estudiantes podrán
								postular y acceder de acuerdo al cumplimiento de los requisitos y vacantes disponibles.
								Las becas son: Beca de Arancel, Beca de Residencia, Beca de Transporte y Beca
								Extraordinaria.
							</p>
							<p class="text-blue mb-4">
								<a href="https://www.mineduc.cl" target="_blank">www.mineduc.cl</a><br>
								<a href="https://www.junaeb.cl" target="_blank">www.junaeb.cl</a><br>
								<a href="https://portal.ingresa.cl" target="_blank">portal.ingresa.cl</a><br>
							</p>
<!--							<div class="row">-->
<!--								<div class="col-xl-8 col-lg-9 col-md-12 col-sm-12">-->
<!--									<profile cargo="Responsable Becas y Beneficios"-->
<!--									         correo="jorge.martinez@uaysen.cl"-->
<!--									         foto="/assets/images/unidades/desarrollo-estudiantil/jorge-martinez.jpg"-->
<!--									         nombre="Jorge Martínez"></profile>-->
<!--								</div>-->
<!--							</div>-->
						</accordion>
						<accordion id="salud-mental" title="Salud Mental"
						           class="mb-4"
						           btn-class="bg-orange text-white"
						           btn-class-active="bg-orange-opacity-50 text-white"
						           parent="#accordion-servicios">
							<p class="text-blue mb-4">
								El Plan de Fortalecimiento e Intervención de Salud Mental
								constituye una iniciativa de la Unidad de Desarrollo Estudiantil que responde a la
								necesidad de la Universidad de Aysén de entregar a sus estudiantes herramientas que les
								permitan abordar, de mejor manera, los diferentes desafíos que se les presentan en los
								ámbitos personal, académico y contextuales, siendo la salud mental es un eje primordial
								para en el bienestar personal y el cumplimiento de sus metas académicas.
							</p>
							<p class="text-blue mb-4">
								El programa de salud mental en la UAysén, en concordancia con el
								Plan Estratégico de Desarrollo Institucional (PEDI), responde a la necesidad de
								considerar en el diseño de las diferentes estrategias de inclusión, permanencia y
								calidad. Es por eso que se articula con las demás unidades de apoyo al estudiante.
							</p>
							<div class="text-blue mb-4">
								<h4>Objetivos específicos</h4>
								<ol>
									<li>
										Fomentar y fortalecer las herramientas relacionadas con el autocuidado y
										bienestar en salud mental del estudiante.
									</li>
									<li>
										Generar mecanismos y procesos que faciliten el abordaje, en forma articulada y
										transversal, de problemáticas de salud mental emergentes que interfieran en el
										bienestar y progresión académica de los estudiantes.

										<div class="table-responsive mt-4">
											<table class="table table-bordered table-sm">
												<thead>
												<tr>
													<th>Objetivos</th>
													<th>Estrategias</th>
													<th>Actividades</th>
												</tr>
												</thead>
												<tbody>
												<tr>
													<td rowspan="3">Fomentar y fortalecer las herramientas relacionadas
														con el autocuidado y bienestar en salud mental del estudiante.
													</td>
													<td rowspan="2">Promoción de la salud mental</td>
													<td>Confección y difusión de material informativo a través de redes
														sociales y/o afiches.
													</td>
												</tr>
												<tr>
													<td>Efemérides</td>
												</tr>
												<tr>
													<td>Prevención de problemas de salud mental</td>
													<td>Talleres Grupales</td>
												</tr>
												<tr>
													<td rowspan="2">Generar mecanismos y procesos que faciliten el
														abordaje, en forma articulada y transversal, de problemáticas de
														salud mental emergentes que interfieran en el bienestar y
														progresión académica de los estudiantes.
													</td>
													<td>Desarrollo de sistema de solicitud de atención en línea que
														facilite la gestión y sistematización de la atención
														psicológica.
													</td>
													<td>Formularios de derivación en plataforma UCampus</td>
												</tr>
												<tr>
													<td>Difusión a las demás unidades de apoyo y académica del sistema
														de derivación a salud mental de la UDE a través de un formulario
														único de derivación desde la plataforma de UCampus de la
														Universidad de Aysén.
													</td>
													<td>Reunión informativa y de evaluación con las jefaturas de
														carrera, equipo de unidades de apoyo al estudiante secretaría
														académica y a quién corresponda
													</td>
												</tr>
												<tr>
													<td rowspan="2">Ofrecer e implementar atenciones de psicoterapia
														individual en problemáticas de salud mental de baja complejidad
														favoreciendo la derivación oportuna a centros de atención
														especializados de la red asistencial.
													</td>
													<td>Gestión de las solicitudes de atención espontánea de los/las
														estudiantes y derivaciones de las diferentes unidades según el
														Reglamento de Atención Psicológica de la Unidad de Desarrollo
														Estudiantil de la Universidad de Aysén.
													</td>
													<td>Citar, evaluar, e iniciar psicoterapia individual cuando así
														corresponda y en los tiempos que determina el Reglamento de
														Atención Psicológica
													</td>
												</tr>
												<tr>
													<td>Difusión del Reglamento de Atención Psicológica a los/las
														estudiantes, jefaturas de carrera y Unidades de Apoyo desde la
														plataforma UCampus de la Universidad de Aysén
													</td>
													<td>Poner a disposición de los interesados, el Reglamento de
														Atención Psicológica a través de la plataforma UCampus u otros
														medios de difusión en línea según requerimientos
													</td>
												</tr>
												</tbody>
											</table>
										</div>
									</li>
									<li>Ofrecer e implementar atenciones de psicoterapia individual en problemáticas de
										salud mental de baja complejidad favoreciendo la derivación oportuna a centros
										de atención especializados de la red asistencial.
									</li>
								</ol>
							</div>
							<p class="text-blue mb-4">
								El protocolo de atención psicológica funciona de la siguiente manera:
							</p>
							<p class="text-blue mb-4">
								El o la estudiante que desee solicitar atención psicológica deberá hacerlo a través del
								formulario Solicitud de Atención Psicológica.
							</p>
							<p class="text-blue mb-4">
								En los casos en que él o la estudiante no se encontrara en condiciones de solicitar
								atención psicológica en forma autónoma, se aceptarán solicitudes de atención en su
								nombre desde las diferentes unidades de la Universidad facultadas para efectuar dicha
								derivación, a través del formulario de Derivación Atención Psicológica.
							</p>
							<div class="text-blue mb-4">
								<h4>Estas unidades corresponden a:</h4>
								<ul>
									<li>Desarrollo Estudiantil</li>
									<li>Acompañamiento Académico</li>
									<li>Jefaturas de Carrera</li>
									<li>Secretaría Académica.</li>
								</ul>
							</div>

							<div class="text-blue mb-4">
								La atención psicológica que la Universidad de Aysén ofrece a sus estudiantes es de
								carácter voluntario, es decir, él o la estudiante puede rechazar o poner término a las
								atenciones en cualquier momento sin que esto repercuta en su progresión académica.
							</div>
							<div class="text-blue mb-4">
								Conforme a la ley 20.548 de derechos y deberes de los pacientes, la información personal
								entregada por el/la estudiante en el contexto de las atenciones psicológicas, será
								estrictamente confidencial y utilizada exclusivamente con fines terapéuticos.
							</div>

							<img class="img-fluid"
							     src="/assets/images/unidades/desarrollo-estudiantil/flujo.png" alt="">

							<!--div class="row">
								<div class="col-xl-8 col-lg-9 col-md-12 col-sm-12">
									<profile cargo="Responsable"
									         correo="veronica.montero@uaysen.cl"
									         foto="/assets/images/unidades/cuidados/veronica_montero_catalan.jpg"
									         nombre="Verónica Montero Catalán"></profile>
								</div>
							</div-->
						</accordion>

						<accordion id="actividades-extraprogramaticas" title="Actividades extraprogramáticas"
						           class="mb-4"
						           btn-class="bg-orange text-white"
						           btn-class-active="bg-orange-opacity-50 text-white"
						           parent="#accordion-servicios">
							<h3 class="text-blue mb-4">Actividad Física, recreación y salud</h3>
							<p class="text-blue mb-4">
								La Universidad de Aysén otorga especial relevancia a las actividades artísticas,
								recreativas, deportivas y al aire libre, ya que estas promueven y propician un
								desarrollo integral de los estudiantes, impactando en ámbitos como el estado de ánimo,
								salud física y mental; así como promover instancias de socialización entre pares.
							</p>
							<p class="text-blue mb-4">
								Tenemos dentro de la oferta de actividades extraprogramáticas, talleres y actividades
								elegidas por los estudiantes de carácter artístico, ambiental, cultural, social,
								deportivas, en contacto con la naturaleza y desarrollo personal que se enmarcan dentro
								de los valores de la universidad.
							</p>
							<p class="text-blue mb-4">
								El proceso participativo para la elección de talleres y actividades
							</p>
							<p class="text-blue mb-4">
								Desarrollo estudiantil trabaja en un esfuerzo especial para tener una oferta rica y
								nutrida de actividades extra programáticas en el contexto universitario; lo que se
								genera mediante un proceso participativo por parte de la comunidad universitaria de
								manera abierta a los distintos estamentos universitarios.
							</p>
							<h4 class="text-blue mb-4">
								Estas son las etapas que anualmente se desarrollan:
							</h4>
							<p class="text-blue mb-4">
								<b>Diciembre</b>: Abrimos la posibilidad para que se enviaran ideas y propuestas de
								talleres, a estudiantes, académicos, funcionarios.
							</p>
							<p class="text-blue mb-4">
								<b>Enero</b>: Sistematizamos propuestas en talleres y actividades para ofertar a los
								estudiantes durante el año académico
							</p>
							<p class="text-blue mb-4">
								<b>Marzo</b>: Abrimos un proceso de votación de Estudiantes de la Universidad. Con esta
								votación se le da prioridad a los talleres a implementar.
							</p>
							<p class="text-blue mb-4">
								<b>Abril</b>: se inician los talleres para estudiantes con una duración de 4 a 12
								semanas de duración. Todos los grupos de estudiantes en torno a un taller los
								organizamos y coordinamos mediante grupos whatsapp.
							</p>
							<p class="text-blue mb-4">
								Todos los talleres tienen una evaluación por parte de los estudiantes lo que nos permite
								retroalimentarnos de los éxitos y fracasos de los talleres. Informate en Ucampus el
								estado de los talleres, horarios, y otros detalles.
							</p>
							<div class="row">
								<div class="col-xl-8 col-lg-9 col-md-12 col-sm-12">
									<profile cargo="Responsable"
									         correo="jeannette.monsalve@uaysen.cl"
									         foto="/assets/images/unidades/desarrollo-estudiantil/jeannette-monsalve.jpg"
									         nombre="Francisco Vio Giacaman"></profile>
								</div>
							</div>

							<div class="row">
								<div class="col-12">
									<div class="h5 text-blue my-5">
										<h4 class="text-blue mb-4">Talleres I semestre 2022</h4>
									</div>
								</div>
							</div>
							<div class="row justify-content-center">
								<modal v-for="(actividad, index) in actividades"
								       ref="modal" size="xl"
								       container-class="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-4"
								       :scrollcrable="false">
									<template v-slot:trigger="{modal}">
										<button @click="modal.open"
										        v-html="actividad.text"
										        :class="classesByIndex(index)">
										</button>
									</template>
									<template v-slot:body>
										<img :src="actividad.image" :alt="actividad.text" class="w-100"
										     draggable="false">
									</template>
									<template v-slot:footer="{modal}">
										<a class="btn btn-sky-blue mx-auto"
										   @click="modal.close"
										   :href="actividad.image" target="_blank">
											Descargar
										</a>
									</template>
								</modal>
							</div>

<!--							<img class="img-fluid w-100 d-block" src="/assets/images/unidades/desarrollo-estudiantil/horarios.png" alt="">-->
						</accordion>

						<accordion id="beneficios-madres-padres-estudiantes"
						           title="Beneficios Madres y Padres Estudiantes"
						           class="mb-4"
						           btn-class="bg-orange text-white"
						           btn-class-active="bg-orange-opacity-50 text-white"
						           parent="#accordion-servicios">

							<p class="text-blue mb-4">
								Buscamos asegurar a las y los estudiantes madres y padres, y futuras madres y padres,
								condiciones de corresponsabilidad social en el cuidado de sus hijos e hijas, que les
								permitan desarrollarse adecuadamente en la vida universitaria y cumplir con los
								requisitos establecidos en los respectivos planes de estudio, conciliando sus
								actividades académicas y sus responsabilidades familiares.
							</p>
							<p class="text-blue mb-4">
								Pueden acceder a estos beneficios aquellos estudiantes madres o padres de niños/as hasta
								los 10 años de edad.
							</p>
							<p class="text-blue mb-4">
								Podrás acceder a beneficios tales como:
							</p>
							<div class="text-blue mb-4">
								<ol type="A">
									<li>Pre y post natal</li>
									<li>Beneficios parentales especiales por maternidad o paternidad</li>
									<li>Medidas de flexibilidad académica</li>
								</ol>
							</div>
							<p class="text-blue mb-4">
								Para acceder a alguno de estos beneficios debes completar el siguiente
								<a href="https://forms.gle/V9LCkbRBqx3RRQdC8" target="_blank">formulario</a> y
								adjuntar la documentación solicitada.
							</p>

							<a href="https://uaysen.cl/uploads/archivos/decreto/genero/aprueba-reglamento-corresponsabilidad-social-universidad-de-aysen.pdf"
							   target="_blank"
							   class="btn btn-sky-blue d-block shadow w-100 mx-auto mb-3">
								Reglamento de Corresponsabilidad Social de la Universidad de Aysén
							</a>
						</accordion>

						<accordion id="asociatividad-liderazgo" title="Asociatividad y Liderazgo"
						           class="mb-4"
						           btn-class="bg-orange text-white"
						           btn-class-active="bg-orange-opacity-50 text-white"
						           parent="#accordion-servicios">
							<p class="text-blue mb-4">Tenemos como propósito incentivar y facilitar los espacios y
								mecanismos necesarios para generar participación estudiantil al interior de la
								Institución, así como también fortalecer vínculos con instituciones externas que
								fortalezcan las oportunidades para construir vida Universitaria, acciones ciudadanas, y
								propuestas en beneficios de la buena convivencia entre estudiantes.</p>
							<p class="text-blue mb-4">Dentro de los objetivos a desarrollar de la Sub Unidad están:</p>
							<div class="text-blue mb-4">
								<ul>
									<li>Organizar e incentivar la participación de Centros de Estudiantes por carrera.
									</li>
									<li>Diseñar y coordinar espacios de Desarrollo Estudiantil (por ejemplo, trabajos de
										verano, Bolsa de Trabajo, voluntariados)
									</li>
									<li>Promover la asociatividad y fortalecimiento del liderazgo estudiantil,
										contribuyendo al desarrollo de organizaciones, iniciativas, proyectos y
										vinculaciones con otras organizaciones estudiantiles y comunales.
									</li>
									<li>Gestionar propuestas e iniciativas estudiantiles de carácter cultural y
										artístico.
									</li>
									<li>Incentivar y apoyar propuestas de formación integral de las carreras tales como,
										seminarios, jornadas con otras IES, encuentros regionales y nacionales.
									</li>
								</ul>
							</div>
							<p class="text-blue mb-4">
								Dudas, consultas, propuestas escríbeme a <a href="mailto:camilla.molettieri@uaysen.cl">
								camilla.molettieri@uaysen.cl</a>
							</p>
						</accordion>
					</div>

				</div>
				<div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
					<div class="bg-gray py-5 mb-5">
						<div class="mx-5">
							<div class="h5 text-center text-blue fw-bold mb-4">Accesos Relevantes</div>
							<ul class="list-unstyled">
								<li v-for="relevante in relevantes">
									<a :href="relevante.link"
									   v-html="relevante.text"
									   class="btn btn-sky-blue d-block mb-3 text-center text-white shadow"
									   target="_blank"/>
								</li>
							</ul>
						</div>
					</div>

					<div class="row">
						<div class="col-12">
							<div class="rounded bg-blue-opacity-50 text-white text-center p-2 h5 mb-3">
								Horarios de funcionamiento
							</div>
						</div>
					</div>
					<ul class="list-unstyled">
						<li class="text-blue fw-bold text-center">Casa Angol, Magallanes 511</li>
						<li class="text-blue text-center">Lunes a viernes: 09:00 a 13:00 / 14:30 a 18:00</li>
					</ul>
					<div class="row">
						<div class="col-12">
							<div class="rounded bg-blue-opacity-50 text-white text-center p-2 h5 mb-3">
								Repositorio de documentos
							</div>
						</div>
					</div>
					<div class="list-group">
						<a v-for="doc in repositorio"
						   :href="doc.link" target="_blank"
						   class="list-group-item list-group-item-action d-flex justify-content-start align-items-center animate__animated animate__zoomIn">
							<i class="fa fa-file-pdf text-blue me-3"></i>
							{{ doc.text }}
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import HeaderBox from "../components/HeaderBox";
import LinkTo from "../components/LinkTo";
import mock from "../common/mock";
import Profile from "../components/Profile";
import Accordion from "../components/Accordion";
import Modal from "../components/Modal";

export default {
	name: "servicio-desarrollo-estudiantil",
	components: {LinkTo, HeaderBox, Profile, Modal, Accordion},
	data: () => ({
		repositorio: [
			{text: 'Reglamento General de Estudiantes de Pregrado de la Universidad de Aysén', link: 'assets/pdf/reglamentos/158.REGLAMENTO DE ESTUDIANTES DE PREGRADO.pdf'},
			{text: 'Reglamento de Conducta Estudiantil de la Universidad de Aysén', link: 'assets/pdf/reglamentos/159 REGLAMENTO DE CONDUCTA ESTUDIANTIL.pdf'},
			{text: 'Reglamento General de Estudios de Pregrado de la Universidad de Aysén', link: 'assets/pdf/reglamentos/160.REGLAMENTO DE ESTUDIOS DE PREGRADO.pdf'},
			{text: 'Modifica Reglamento General de Estudios de Pregrado de la Universidad de Aysén', link: 'assets/pdf/reglamentos/218.MODIFICACIÓN DUE 160.pdf'},
			{text: 'Reglamento de Reconocimiento de Estudios', link: 'assets/pdf/reglamentos/161.REGLAMENTO DE RECONOCIMIENTO DE ESTUDIOS.pdf'},
			{text: 'Reglamento de Ayudantías Estudiantiles de la Universidad de Aysén', link: 'assets/pdf/reglamentos/1242. REGLAMENTO DE AYUDANTIAS.pdf'},
			{text: 'Reglamento de Fondos de Desarrollo Estudiantil', link: 'assets/pdf/reglamentos/1857.REGLAMENTO DE FONDO DE DESARROLLO ESTUDIANTIL.pdf'},
		],
	}),
	mounted() {
		if (this.$route.hash) {
			this.openAccordion(this.$route.hash);
		}
	},
	methods: {
		openAccordion(hash) {
			let container = document.getElementById('accordion-servicios');
			[].slice.call(container.querySelectorAll('.collapse')).map(function (el) {
				let instance = bootstrap.Collapse.getOrCreateInstance(el, {
					toggle: false
				});
				if (instance._element.id === hash.substring(1)) {
					instance.show();
					instance._element.setAttribute('tabindex', 0);
					setTimeout(function () {
						instance._element.focus();
					}, 300);
				} else {
					instance.hide();
				}
				return instance;
			});
		},
		doScrolling(element, duration = 300, offset = 0) {
			let startingY = window.pageYOffset
			let elementY = window.pageYOffset + document.querySelector(element).getBoundingClientRect().top;
			let targetY = document.body.scrollHeight - elementY < window.innerHeight ? document.body.scrollHeight - window.innerHeight : elementY;
			let diff = targetY - startingY;
			let easing = function (t) {
				return t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
			};
			if (!diff) {
				return
			}
			let start
			let step = function (timestamp) {
				if (!start) {
					start = timestamp
				}
				let time = timestamp - start
				let percent = Math.min(time / duration, 1)
				percent = easing(percent)
				window.scrollTo(0, (startingY + diff * percent) - offset)
				if (time < duration) {
					window.requestAnimationFrame(step);
				}
			}
			window.requestAnimationFrame(step);
		},
		classesByIndex(index) {
			const value = index % 3;
			let color = 'btn-cyan';
			if (value === 1) {
				color = 'btn-pink';
			} else if (value === 2) {
				color = 'btn-purple';
			}
			return 'btn d-block w-100 h-100 text-white p-5 shadow ' + color;
		}
	},
	computed: {
		relevantes() {
			return mock.relevantes;
		},
		actividades() {
			return mock.actividadesSemestre1;
		},
	},
	watch: {
		$route(to) {
			if (to.hash) {
				this.openAccordion(to.hash);
			}
		}
	}
}
</script>
