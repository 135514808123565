<template>
	<div class="footer bg-blue bg-tree">
		<div class="container">
			<div class="row py-5">
				<div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 py-5">
					<div class="text-white">
						<ul class="list-unstyled">
							<li><h5>Contacto</h5></li>
							<li class="footer-link">Informaciones: (67) 2332201</li>
							<li class="footer-link">Rectoría: (67) 2332202</li>
							<li class="footer-link">Planificación: (67) 2332203</li>
							<li class="footer-link">Dirección académica: (67) 2 332235</li>
							<li class="footer-link">Desarrollo estudiantil: (67) 2332237</li>
							<li class="footer-link">Vinculación con el Medio (67) 2332203</li>
						</ul>
					</div>
				</div>
				<div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 py-5">
					<ul class="text-white list-unstyled">
						<li><h5>Nuestros campus</h5></li>
						<li class="footer-link"><b>Campus Río Simpson</b></li>
						<li class="footer-link"><small>Obispo Vielmo 62, Coyhaique</small></li>
						<li class="footer-link"><b>Campus Lillo</b></li>
						<li class="footer-link"><small>Eusebio Lillo 667, Coyhaique</small></li>
						<li class="footer-link"><b>Estación de Investigación Cerro Castillo</b></li>
						<li class="footer-link"><small>Carretera, Río Ibáñez</small></li>
					</ul>
				</div>
				<div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 py-5">
					<ul class="text-white list-unstyled">
						<li><h5>Sitios</h5></li>
						<li class="footer-link"><a href="https://uaysen.cl/repositorio">Repositorio UAysén</a></li>
						<li class="footer-link"><a href="https://sai.uaysen.cl/app/glogin?next=/x/">Sistema
							Información Institucional</a></li>
						<li class="footer-link"><a href="https://ucampus.uaysen.cl/">Ucampus</a></li>
						<li class="footer-link"><a href="http://biblioteca.uaysen.cl/">Biblioteca</a></li>
						<li class="footer-link"><a
							href="https://www.portaltransparencia.cl/PortalPdT/pdtta?codOrganismo=UN017">Transparencia</a>
						</li>
						<li class="footer-link"><a href="https://uaysen.cl/trabaja-con-nosotros">Trabaje con
							nosotros</a></li>
						<li class="footer-link"><a href="https://uaysen.cl/solicitud-difusion">Solicitud de
							Difusión</a></li>
					</ul>
				</div>
				<div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 py-5">
					<div class="text-white">
						<ul class="list-unstyled">
							<li><h5>Redes Universitarias</h5></li>
							<li class="footer-link"><a href="https://www.consejoderectores.cl/">Consejo de
								Rectores</a></li>
							<li class="footer-link"><a href="https://www.auregionales.cl/">Agrupación de
								Universidades Regionales</a></li>
							<li class="footer-link"><a href="https://www.uestatales.cl/cue/">Consorcio de
								Universidades</a></li>
							<li class="footer-link">
								<a href="https://uaysen.cl">
									<img src="https://uaysen.cl/assets/frontend/img/logo-footer.png"
									     class="mx-auto w-50 d-block" alt="UAysén">
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12 text-center">
					<ul class="list-inline">
						<li class="list-inline-item mx-3 footer-link">
							<a href="https://www.facebook.com/udeaysen" target="_blank"><i
								class="t-ease-out fab fa-fab fa-facebook-f fa-2x"></i></a>
						</li>
						<li class="list-inline-item mx-3 footer-link">
							<a href="https://www.youtube.com/UniversidaddeAysen" target="_blank"><i
								class="t-ease-out fab fa-fab fa-youtube fa-2x"></i></a>
						</li>
						<li class="list-inline-item mx-3 footer-link">
							<a href="https://www.instagram.com/universidad_de_aysen/" target="_blank"><i
								class="t-ease-out fab fa-fab fa-instagram fa-2x"></i></a>
						</li>
						<li class="list-inline-item mx-3 footer-link">
							<a href="https://twitter.com/UdeAysen" target="_blank"><i
								class="t-ease-out fab fa-fab fa-twitter fa-2x"></i></a>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<svg class="w-100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 10" preserveAspectRatio="none">
			<polygon class="fill-sky-blue" points="50 0 50 10 0 10"></polygon>
		</svg>
		<div class="container-fluid">
			<div class="row bg-sky-blue text-white py-5">
				<div class="col-xl-6 col-lg-6 col-md-8 col-sm-12 text-center">
					Universidad de Aysén {{ new Date().getFullYear() }} ©
				</div>
				<div class="col-xl-6 col-lg-6 col-md-4 col-sm-12 text-center">
					www.<h3 class="d-inline-block">uaysen</h3>.cl<br>
				</div>
			</div>
		</div>
		
		<div class="w-100 py-3 bg-cyan"></div>
	</div>
</template>
<script>

export default {
	name: "main-footer",
	computed: {}
}
</script>