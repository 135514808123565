<template>
	<div>
		<header-box image="/assets/images/pace.jpg"
		            :opacity="true"
		            opacity-color="bg-cyan"
		            class-color="bg-blue">
			<div class="fs-2 fw-lighter text-start text-xl-start text-lg-start text-md-center text-sm-center w-100 text-white">
				Para ti
			</div>
			<div class="h2 text-start text-xl-start text-lg-start text-md-center text-sm-center w-100 text-white">
				Acompañamiento en la Educación (PACE)
			</div>
		</header-box>
		
		<div class="container">
			<div class="row mb-5">
				<div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 py-5">
					<div class="row">
						<div class="col-xl-7 col-lg-9 col-md-12 col-sm-12">
							<div class="title bg-green mb-3">¿Qué es el PACE?</div>
						</div>
					</div>
					<p class="text-blue mb-5">
						El Programa de Acceso a la Educación Superior (PACE) es un programa del Ministerio de Educación
						(MINEDUC) en el cual participan más de 28 Instituciones de Educación Superior, entre ellas la
						Universidad de Aysén, su principal objetivo es permitir el acceso a la Educación Superior de
						estudiantes destacados en Enseñanza Media mediante la realización de acciones de preparación y
						apoyo permanentes asegurando cupos adicionales a la oferta académica de las universidades.
					</p>
					<div class="row">
						<div class="col-12">
							<div class="title bg-pink mb-3">¿Cómo el PACE sigue acompañándome en la Universidad?</div>
						</div>
					</div>
					<p class="text-blue mb-5">
						En la Universidad de Aysén la componente de Acompañamiento en la Educación Superior (AES)
						ofrece apoyo académico y psicoeducativo a los y las estudiantes PACE que resulten habilitados y
						se matriculen en la institución. Esto significa que si eres estudiante PACE el equipo AES estará
						para apoyarte en todo momento, cuando tengas dificultades para enfrentar asignaturas complejas,
						necesites renovar o postular a beneficios o tengas dudas sobre tu carrera. Durante los dos
						primeros años en la universidad AES estará acompañándote en esta nueva aventura de ingresar y
						adaptarse a la educación superior.
					</p>
					<div class="row">
						<div class="col-12">
							<div class="title bg-cyan mb-3">¿Cómo solicitar apoyo u orientación?</div>
						</div>
					</div>
					<p class="text-blue mb-5">
						Escribiéndonos a nuestro correo electrónico <a href="mailto:aes@uaysen.cl" target="_blank">aes@uaysen.cl</a>
					</p>
				</div>
				<div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
					<img src="/assets/images/logo-pace.png" class="img-fluid my-5 p-5" alt="">
					<div class="row">
						<div class="col-12">
							<div class="rounded bg-blue-opacity-50 text-white text-center p-2 h5 mb-3">
								Horarios de funcionamiento
							</div>
						</div>
					</div>
					<ul class="list-unstyled">
						<li class="text-blue fw-bold text-center">Casa Angol, Magallanes 511</li>
						<li class="text-blue text-center">Lunes a viernes: 09:00 a 13:00 / 14:30 a 18:00</li>
					</ul>
				</div>
			</div>
		</div>
		
		<div class="bg-dark-gray py-5">
			<div class="container">
				<div class="row">
					<div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-5">
						<profile cargo="Responsable Programa PACE componente AES"
						         correo="david.vasquez@uaysen.cl"
						         foto="/assets/images/unidades/pace/david-vasquez.jpg"
						         nombre="David Vásquez"></profile>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import HeaderBox from "../components/HeaderBox";
import LinkTo from "../components/LinkTo";
import mock from "../common/mock";
import Profile from "../components/Profile";

export default {
	name: "servicio-pace",
	components: {LinkTo, HeaderBox, Profile},
	data: () => ({}),
	methods: {},
	computed: {}
}
</script>