<template>
	<div class="card px-3 position-relative bg-transparent border-0">
		<div class="top-0 start-0 w-100 h-100 d-none d-xl-block d-lg-block d-md-block d-sm-none">
			<div
				class="position-absolute bg-transparent start-0 top-0 h-50 w-100 d-flex align-content-center justify-content-center flex-column">
				<div class="container-fluid">
					<div class="row">
						<div class="col-md-7 offset-md-5">
							<div class="fw-bold text-blue" v-html="cargo"></div>
						</div>
					</div>
				</div>
			</div>
			<div
				class="position-absolute bg-middle-cyan start-0 bottom-0 h-50 w-100 d-flex align-content-center justify-content-center flex-column">
				<div class="container-fluid">
					<div class="row">
						<div class="col-md-7 offset-md-5">
							<div class="fw-bold text-blue" v-html="nombre"></div>
							<div class="fw-bold text-blue" v-html="correo"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row zi-1 g-0">
			<div class="col-md-5 d-flex justify-content-center px-4">
				<img :src="image"
				     :alt="nombre"
				     draggable="false"
				     class="bg-cyan rounded-circle img-thumbnail p-2 mx-5 my-4">
			</div>
		</div>
		
		<div class="position-relative d-block d-xl-none d-lg-none d-md-none d-sm-block">
			<div class="row">
				<div class="col-md-7 offset-md-5 bg-middle-cyan text-center p-2">
					<div class="fw-bold text-blue" v-html="cargo"></div>
					<div class="fw-bold text-blue" v-html="nombre"></div>
					<div class="fw-bold text-blue" v-html="correo"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'profile',
	props: {
		cargo: {
			type: String,
			required: true
		},
		nombre: {
			type: String,
			required: true
		},
		correo: {
			type: String,
			required: true
		},
		foto: {
			type: String,
			required: true
		},
	},
	mounted() {
	},
	methods: {},
	computed: {
		image() {
			return this.foto ? this.foto : '/assets//images/avatar.jpg';
		}
	}
	
}
</script>
